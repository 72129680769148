<template>
  <div>
    <HeaderPanel
      title="VIDEO"
      :filter="filter"
      placeholder="Search Name"
      @sidebar="sidebarFilter"
      @search="getList"
      :hasDropdown="false"
      routerPath="/video/details/0"
    />
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      placeholder="Search Name"
      @searchAll="getList"
      @clearFilter="clearFilter"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(name)="data">
              <div>
                <router-link :to="'/video/details/' + data.item.id">
                  <b-button variant="link" class="name-link">
                    {{ data.item.name }}
                  </b-button>
                </router-link>
              </div>
            </template>
            <template v-slot:cell(media_url)="data">
              <div
                class="d-flex justify-content-center"
                v-if="data.item.video_type === 2"
              >
                <video class="box-video" controls>
                  <source :src="data.item.media_url" type="video/mp4" />
                </video>
              </div>
              <div
                class="box-video position-relative video-thumbnail yt-box"
                v-else
              >
                <iframe
                  width="250"
                  height="125"
                  frameborder="0"
                  allow="clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  :src="data.item.media_url"
                />
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div v-if="data.item.status == 1" class="text-success">
                Active
              </div>
              <div v-else class="text-error">Inactive</div>
            </template>
            <template v-slot:cell(id)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/video/details/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-error px-1 py-0"
                  @click="AlertDelete(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import SideBarFilter from "@/components/SideBarFilter";
export default {
  components: {
    HeaderPanel,
    Pagination,
    SideBarFilter,
  },
  name: "VideoIndex",
  data() {
    return {
      fields: [
        {
          key: "media_url",
          label: "Video",
          thStyle: { width: "22%" },
          tdClass: "p-3",
        },
        {
          key: "name",
          label: "Name",
          thStyle: { width: "25%" },
        },

        {
          key: "sort_order",
          label: "Sort Order",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "id",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxActive: false,
      checkboxInactive: false,
      filter: {
        search: "",
        page: 1,
        take: 10,
        status: [],
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: 0,
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.status =
        this.filter.status.length == 1 ? this.filter.status[0] : null;
      await this.$store.dispatch("ActionGetVideoList", payload);
      var data = await this.$store.state.moduleVideo.stateGetVideoList;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
        if (this.filter.status !== null) {
          [this.filter.status];
        }
      }
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
      };
    },
    AlertDelete(id) {
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteData(id);
        }
      });
    },
    deleteData: async function (id) {
      this.isLoadingData = true;
      await this.$store.dispatch("ActionDeleteVideo", id);
      var data = await this.$store.state.moduleVideo.stateDeleteVideo;
      if (data.result == 1) {
        this.isLoadingData = false;
        this.successAlert().then(() => {
          this.getList();
        });
      }
    },
  },
};
</script>

<style scoped>
.box-video::before {
  padding-top: 42.9%;
}
</style>
